import { Injectable, OnInit } from '@angular/core';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { Constants } from '../../../consts';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable()
export class LogInService {
  public logInData: any

  constructor(public apiManager: ApiManagerComponent,
  ) {
  }
  getLoginData(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.userLogin, false, data).pipe(map((loginResponse) => {
      return loginResponse
    }))
  }

  loginWithSocial(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.loginWithSocial, false, data).pipe(map((loginResponse) => {
      return loginResponse
    }))
  }
}