import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { map } from "rxjs/operators";
import { Constants } from '../../consts';
import { UserAccess } from '../../models/userAccessControl.model';

@Injectable({
  providedIn: 'root'
})
export class UserAccessControl {

  constructor(
    private apiManager: ApiManagerComponent,
  ) { }

  userAccessControl(data: any): Observable<UserAccess> {
    return this.apiManager.postMethod(Constants.userAccessControl, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
}
