import {
  subDomainUpdate
} from './subDomain.actions';
import { initialState } from './subDomain.state';
import { createReducer, on } from '@ngrx/store';

const _subDomainReducer = createReducer(
  initialState,
  on(subDomainUpdate, (state, action) => {
    return {
      ...state,
      enableSubDomain: action.enableSubDomain,
      apiCall: action.apiCall
    };
  })
);

export function subDomainReducer(state: any, action: any) {
  return _subDomainReducer(state, action);
}
