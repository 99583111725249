import { environment } from "../environments/environment";

export class Constants {
  //public static logoImg = '../assets/images/logo.png';
  public static advertiseLogo = '../assets/images/AdvertiseIcon.png';
  public static infoLogo = '../assets/images/AdvertiseInfoIcon.png';
  public static logoImg = '../assets/images/koacheeLogo.png';
  public static noImg = '../assets/images/female.png';
  public static locationImage = '../assets/images/location.png';
  public static ratingImage = '../assets/images/ratingImage.png';
  public static projectName = 'Koachee';
  public static allCountries = './assets/json/locations/allCountries.json';
  public static allStates = './assets/json/locations/allStates.json';
  public static allCities = './assets/json/locations/allCities.json';
  public static switchUser = '../assets/images/switchUser.png';
  public static kWord = '../assets/images/kWord.jpg';
  public static skypeImage = '../assets/images/skypeImage.png';
  public static likeCoach = '../assets/images/like.png';
  public static unlikeCoach = '../assets/images/unlike.png';
  public static loactionImage = '../assets/images/locationPointer.png';
  public static quesImage = '../assets/images/Q.png';
  public static favicon = '../assets/images/favicon.png';
  
  // Routes Path
  public static homePath = 'home';
  public static loginPath = 'login';
  public static signUpPath = 'signup';
  public static signUpOrgPath = 'signup-org';
  public static coachSignUpPath = 'coachsignup';
  public static coacheeSignUpPath = 'coacheesignup';
  public static coachListPath = 'coach-list';
  public static completeSignUpAccountPath = 'complete-signup-account';
  public static coacheeQuestionsPath = 'coachee-questions';
  public static accountPath = 'account';
  public static coachProfilePath = 'coach/:Handle';
  public static helpPath = 'help';
  public static chatPath = 'messaging';
  public static subdomainCoachProfilePath = 'subdomain-coach';

  // public static HomePath = 'home';
  public static privacyPolicyPath = 'privacy-policy';
  public static termsOfServicePath = 'terms-of-service';
  public static forgotPasswordPath = 'forgot-password';
  public static koacheeOffersListPath = 'coachee-offers-list';
  public static koacheeProposalsListPath = 'coachee-proposals-list';
  public static invoiceListPath = 'invoice-list';
  public static settingsPath = 'settings';
  public static allnotificationspath = 'all-notifications';
  public static verificationPath = 'verification';
  public static koacheeProfilePath = 'coachee/:Handle';
  public static IncompleteProfilePath = 'incomplete-profile';
  public static shareScreenPath = 'share-Screen';
  public static coachesPath = 'coaches/:Expertise';
  public static dashboardPath = 'dashboard';
  public static myNetworkPath = 'my-network';
  public static thankYouPath = 'thank-you-coach';
  public static thankYouCoacheePath = 'thank-you-coachee';
  public static feedUserPath = 'feed/:userId';
  public static feedPath = 'feed';
  public static maintaince = 'maintaince';
  public static coachMyProfilePath = 'mycoachprofile';
  public static koacheeMyProfilePath = 'mycoacheeprofile';
  public static advertisePath = 'advertise'
  public static stripeWebHookPath = 'stripeWebHook'
  public static expertProfileReviewPath = 'expertProfileReview'
  public static koacheeCompleteProfilePath = 'completeProfile'
  public static videoCall = 'video/:videoID'
  public static schedulePath = 'schedule'
  public static appointmentsPath = 'sessions'
  public static koacheeSuitePath = 'koachee-suite'
  public static coachingSuitePath = 'coaching-suite'
  public static resourcesPath = 'resources'
  public static dashboardReportPath = 'report'
  public static profileNotFoundPath = 'profile-not-found';
  public static whiteBoardPath = 'white-board';
  public static loaderIoPath = 'loaderio-cb76aee95a80eec06042ea930f456b20';
  public static subDomainPath = 'default';


  public static signUp = environment.baseUrl + "/router/signup";
  public static checkToken = environment.baseUrl + "/router/checkToken";
  public static getAll = environment.baseUrl + "/router/getAll";
  public static getAllWithoutToken = environment.baseUrl + "/router/getAllWithoutToken";
  public static getProfile = environment.baseUrl + "/router/getProfile?Handle=";
  public static getShareShortanLink = environment.baseUrl + "/router/getShareShortanLink";
  public static getIndviualsMessage = environment.baseUrl + "/router/getIndviualsMessage?UserId=";
  public static getAllMessage = environment.baseUrl + "/router/getAllMessage";
  public static insertMessage = environment.baseUrl + "/router/insertMessage";
  public static updateProfile = environment.baseUrl + "/router/updateProfile";
  public static userLogin = environment.baseUrl + "/router/login";
  public static userExists = environment.baseUrl + "/users/checkEmailExists";
  public static forgotPassword = environment.baseUrl + "/router/forgotPassword";
  public static checkforgotPassworkLink = environment.baseUrl + "/router/checkforgotPassworkLink";
  public static updatePassword = environment.baseUrl + "/router/updatePassword";
  public static sendOffer = environment.baseUrl + "/users/sendOffer";
  public static getAllOffersList = environment.baseUrl + "/users/getAllOffersDetails";
  public static offerStatusUpdate = environment.baseUrl + "/users/offerStatusUpdate";
  public static invoiceSubmitted = environment.baseUrl + "/users/invoiceSubmitted";
  public static getContractDetails = environment.baseUrl + "/users/getContractDetails";
  public static getSessionStatus = environment.baseUrl + "/users/getSessionStatus";
  public static getInvoice = environment.baseUrl + "/users/getInvoice";
  public static paymentRequest = environment.baseUrl + "/users/paymentRequest";
  public static stripeRequest = environment.baseUrl + "/users/stripeRequest";
  public static stripeUserInfo = environment.baseUrl + "/router/stripeUserInfo";
  public static getStripeUserInfo = environment.baseUrl + "/router/getStripeUserInfo";
  public static getHomeData = environment.baseUrl + "/router/getHomeData?Handle=";
  public static getStyleData = environment.baseUrl + "/router/getStyleData?Handle=";
  public static getAllNotifications = environment.baseUrl + "/router/getAllNotifications";
  public static updateNotificationReadStatus = environment.baseUrl + "/router/updateNotificationReadStatus";
  public static setUserOffline = environment.baseUrl + "/users/setUserOffline";
  public static updateUserType = environment.baseUrl + "/users/updateUserType";
  public static getRatingQuestion = environment.baseUrl + "/users/getRatingQuestion";
  public static reviewByKoachee = environment.baseUrl + "/users/reviewByKoachee";
  public static submitFeedback = environment.baseUrl + "/users/submitFeedback";
  public static addFavourite = environment.baseUrl + "/router/addFavourite";
  public static getAllFavourite = environment.baseUrl + "/router/getAllFavourite";
  public static removeFavourite = environment.baseUrl + "/router/removeFavourite";
  public static updateSkypeID = environment.baseUrl + "/router/updateSkypeID";
  public static verifyEmail = environment.baseUrl + "/router/verifyEmail";
  public static resendVerifyEmail = environment.baseUrl + "/router/resendVerifyEmail";
  public static addEditPackage = environment.baseUrl + "/router/addEditPackage";
  public static deletePackage = environment.baseUrl + "/router/deletePackage";
  public static getMethodWithoutToken = environment.baseUrl + "/router/getMethodWithoutToken?Handle=";
  public static getProfileWithoutToken = environment.baseUrl + "/router/getProfileWithoutToken?Handle=";
  public static uploadImage = environment.baseUrl + "/router/blob/uploadFile";
  public static isFollow = environment.baseUrl + "/router/isFollow";
  public static getAllFollow = environment.baseUrl + "/router/getAllFollow";
  public static loginWithSocial = environment.baseUrl + "/router/loginWithSocial";
  public static coacheeInterview = environment.baseUrl + "/router/coacheeInterview";
  public static resetPassword = environment.baseUrl + "/router/resetPassword";
  public static resetProfile = environment.baseUrl + "/router/resetProfile";
  public static updateRateAndPackages = environment.baseUrl + "/router/updateRateAndPackages";
  public static updateFreeDemoStatus = environment.baseUrl + "/router/updateFreeDemoStatus";
  // public static isProfilePublished = environment.baseUrl + "/router/isProfilePublished";
  public static profileUnpublished = environment.baseUrl + "/router/profileUnpublished";
  public static getAllSkills = environment.baseUrl + "/users/getAllSkills";
  public static getOtherIntegrationInfo = environment.baseUrl + "/router/getOtherIntegrationInfo";
  public static getNotificationData = environment.baseUrl + "/router/getNotificationData";
  public static updateNotificationTabSetting = environment.baseUrl + "/router/updateNotificationTabSetting";
  public static getExpertiseData = environment.baseUrl + "/router/getExpertiseData";
  public static getExpertiseWithoutToken = environment.baseUrl + "/router/getExpertiseWithoutToken?Handle=";
  public static stripeFund = environment.baseUrl + "/router/stripeFund";
  public static stripeRelease = environment.baseUrl + "/router/stripeRelease";
  public static getInvoiceDetail = environment.baseUrl + "/router/getInvoiceDetail";
  public static dashboardData = environment.baseUrl + "/router/dashboardData";
  public static dashboardReportData = environment.baseUrl + "/router/dashboardReportData?OrgID=";
  public static getSingleUserReport = environment.baseUrl + "/router/getSingleUserReport";
  public static getSingleClientReport = environment.baseUrl + "/router/getSingleClientReport";
  public static shareCoach = environment.baseUrl + "/router/shareCoach";
  public static getSingleInvoiceDetail = environment.baseUrl + "/users/getSingleInvoiceDetail";
  public static csvPath = environment.baseUrl + "/csvUpload";
  public static addEditContact = environment.baseUrl + "/router/addEditContact";
  public static getAllContact = environment.baseUrl + "/router/getAllContact";
  public static inviteContact = environment.baseUrl + "/router/inviteContact";
  public static promotionCode = environment.baseUrl + "/router/promotionCode";
  public static publishProfile = environment.baseUrl + "/router/publishProfile";
  public static search = environment.baseUrl + "/router/search?tag=";
  public static insertFeed = environment.baseUrl + "/router/insertFeed";
  public static getFeedList = environment.baseUrl + "/router/getFeedList";
  public static getFeedListWithoutToken = environment.baseUrl + "/router/getFeedListWithoutToken";
  public static getFeedFollowList = environment.baseUrl + "/router/getFeedFollowList";
  public static getFeedData = environment.baseUrl + "/router/getFeedData";
  public static getFeedDataWithoutToken = environment.baseUrl + "/router/getFeedDataWithoutToken";
  public static getProfileFeedData = environment.baseUrl + "/router/getProfileFeedData";
  public static feedImage = environment.baseUrl + "/feed/Image";
  public static resourceFile = environment.baseUrl + "/resource/file";
  public static getPackageData = environment.baseUrl + "/router/getPackageData";
  public static likeUnlikePost = environment.baseUrl + "/router/likeUnlikePost";
  public static addView = environment.baseUrl + "/router/addView";
  public static addViewWithoutToken = environment.baseUrl + "/router/addViewWithoutToken";
  public static checkEndContractStatus = environment.baseUrl + "/users/checkEndContractStatus";
  public static insertComment = environment.baseUrl + "/router/insertComment";
  public static removeImge = environment.baseUrl + "/router/removeImage";
  public static getAdvertiseData = environment.baseUrl + "/router/getAdvertiseData";
  public static advertise = environment.baseUrl + "/router/advertise";
  public static stripeWebHook = environment.baseUrl + "/router/stripeWebHook";
  public static getSubscriptionData = environment.baseUrl + "/router/getSubscriptionData";
  public static cancelSubscription = environment.baseUrl + "/router/cancelSubscription";
  public static getProfilePromotion = environment.baseUrl + "/router/getProfilePromotion";
  public static profilePromotion = environment.baseUrl + "/router/profilePromotion";
  public static updateFundPaymentMethod = environment.baseUrl + "/router/updateFundPaymentMethod";
  public static completeFundedOffer = environment.baseUrl + "/router/completeFundedOffer";
  public static deleteFeed = environment.baseUrl + "/router/deleteFeed";
  public static stripeAccountUpdate = environment.baseUrl + "/router/stripeAccountUpdate";
  public static stripeAccountResync = environment.baseUrl + "/router/stripeAccountResync";
  public static seoTags = environment.baseUrl + "/router/seoTags?Handle=";
  public static testApi = environment.baseUrl + "/test";
  public static scheduler = environment.baseUrl + "/router/scheduler";
  public static getScheduler = environment.baseUrl + "/router/getScheduler";
  public static getFeedUsersList = environment.baseUrl + "/router/getFeedUsersList";
  public static insertSchedule = environment.baseUrl + "/router/insertSchedule";
  public static getScheduleByDate = environment.baseUrl + "/router/getScheduleByDate";
  public static getAllSchedules = environment.baseUrl + "/router/getAllSchedules";
  public static cancelSchedules = environment.baseUrl + "/router/cancelSchedules";
  public static userIntegration = environment.baseUrl + "/router/userIntegration";
  public static enableSchedulePlan = environment.baseUrl + "/router/enableSchedulePlan";
  public static videoCallSchedule = environment.baseUrl + "/router/videoCallSchedule";
  public static video = environment.baseUrl + "/router/video";
  public static getBeforeCallData = environment.baseUrl + "/router/getBeforeCallData";
  public static createNote = environment.baseUrl + "/router/createNote";
  public static insertVideoCallHistory = environment.baseUrl + "/router/insertVideoCallHistory";
  public static updateVideoCallHistory = environment.baseUrl + "/router/updateVideoCallHistory";
  public static getAllNotes = environment.baseUrl + "/router/getAllNotes";
  public static deleteNote = environment.baseUrl + "/router/deleteNote";
  public static exportNotes = environment.baseUrl + "/router/exportNotes";
  public static addEditNoteDescription = environment.baseUrl + "/router/addEditNoteDescription";
  public static cancelKoacheeSuiteSubscription = environment.baseUrl + "/router/cancelKoacheeSuiteSubscription";
  public static koacheeSuite = environment.baseUrl + "/router/koacheeSuite";
  public static koacheeWebSpace = environment.baseUrl + "/router/koacheeWebSpace";
  public static coachingSuite = environment.baseUrl + "/router/coachingSuite";
  public static addScheduleReminderEmail = environment.baseUrl + "/router/addScheduleReminderEmail";
  public static getConferencingData = environment.baseUrl + "/router/getConferencingData";
  public static whiteBoard = environment.baseUrl + "/router/whiteBoard";
  public static getWhiteBoardData = environment.baseUrl + "/router/getWhiteBoardData";
  public static getAllAvailabilites = environment.baseUrl + "/router/getAllAvailabilites";
  public static deleteAvailability = environment.baseUrl + "/router/deleteAvailability";
  public static getSingleAvailability = environment.baseUrl + "/router/getSingleAvailability?AvailabilityID=";
  public static userAccessControl = environment.baseUrl + "/router/userAccessControl";
  public static checkPackageIDDB = environment.baseUrl + "/router/checkPackageIDDB";
  public static checkUserSubDomain = environment.baseUrl + "/router/checkUserSubDomain";
  public static userCustomProfile = environment.baseUrl + "/router/userCustomProfile";
  public static getVideoCallToken = environment.baseUrl + "/router/getVideoCallToken";
  public static startRedecording = environment.baseUrl + "/router/startRedecording";
  public static stopRedecording = environment.baseUrl + "/router/stopRedecording";
  public static downloadVideoCall = environment.baseUrl + "/router/downloadVideoCall";
  public static addFolder = environment.baseUrl + "/router/addFolder";
  public static editFolder = environment.baseUrl + "/router/editFolder";
  public static deleteFolder = environment.baseUrl + "/router/deleteFolder";
  public static getAllResources = environment.baseUrl + "/router/getAllResources";

  public static addResource = environment.baseUrl + "/router/addResource";
  public static getResource = environment.baseUrl + "/router/getResource";
  public static deleteResource = environment.baseUrl + "/router/deleteResource";
  public static getResourceS3 = environment.baseUrl + "/image/";

  
  
  // Local Storage For Profile
  public static profileKey = "profileKey";
  public static tokenKey = "tokenKey";
  public static notificationKey = "notificationKey";
  public static notificationCountKey = "notificationCountKey";
  public static unreadMessagesCountKey = "unreadMessagesCountKey";
  public static publishProfileKey = "publishProfileKey";
  public static expertReviewBarKey = "expertReviewBar";
  public static completeProfileKey = "completeProfile";
  public static notepadKey = "notepad";
  public static resizeNotepadKey = "resizeNotepad";
  public static koacheeSuitePlanKey = "koacheeSuitePlanKey";
  public static suitePlanKey = "suitePlanKey";
  public static styleKey = "styleKey";
  public static extLogoKey = "extLogoKey";


  // Skills
  public static skillsKey = "skillsKey";

  // Events Names for pages
  public static homeEvent = "Home";
  public static ownProfileEvent = "Own_Profile";
  public static coachProfileEvent = "Coach_Profile";
  public static loginEvent = "Login";
  public static signUpEvent = "SignUp";
  public static messagesEvent = "Messages";
  public static getAllCoachesEvent = "Get_All_Coaches";
  public static offerEvent = "Offers";
  public static proposalEvent = "Proposal";
  public static invoiceEvent = "Invoice";
  public static feedEvent = "Feed";
  public static thankYouCoachEvent = "Thank_You_Coach";
  public static thankYouCoacheeEvent = "Thank_You_Coachee";
  public static forgotPasswordEvent = "Forgot_Password";
  public static verificationEvent = "Verification";
  public static completeSignUpEvent = "Complete_SignUp";
  public static dashboardEvent = "Dashboard";
  public static allContactEvent = "All_Contact";
  public static myNetworkEvent = "My_Network";
  public static notificationTabEvent = "Notification_Tab"
  public static pageNotFoundEvent = "Page_Not_Found"
  public static shareScreenEvent = "Share_Screen"
  public static accountEvent = "Account"
  public static settingEvent = "Setting"
  public static expertiseLandingEvent = "Expertise_Landing"
  public static advertisePage = "Advertise_Page"
  public static advertisePlan = "Advertise_Plan"
  public static advertisePlanPaid = "Advertise_Plan_Paid"
  public static cancelSubscriptionEvent = "Subscription_Cancelled"
  public static getSubscriptionDataEvent = "Get_Subscription_Data"
  public static expertProfileEvent = "Get_Expert_Profile_Data"
  public static expertProfilePayEvent = "Expert_Profile_Buy"
  public static koacheeSuitePage = "Koachee_Suite"
  public static coachingSuitePage = "Coaching_Suite"
  public static coachingEvent = "Coaching"



  //EVENTS NAMES FOR COMPONENTS
  public static allNotificationsEvent = "All_Notifications"
  public static viewNotificationEvent = "View_Notification"
  public static passwordEvent = "Password"
  public static logOutEvent = "Log_Out"


  // Home Page Event Names
  public static cardClickedEvent = "Card_Clicked"
  public static contactCoachEvent = "Contact_Coach"
  public static viewFullProfileEvent = "View_Full_Profile"
  public static moreEvent = "More"
  public static reviewsEvent = "Reviews"
  public static CoachProfileButtonEvent = "Coach_profile_Btn"
  public static requestFreeConsultationEvent = "Request_Free_Consultation"
  public static hireCoachButtonEvent = "Hire_Coach"
  public static alreadyHiredPopUpEvent = "Already_Hired_Popup"
  public static noRatesPublishedPopUpEvent = "No_Rates_Published_Popup"
  public static EmailVerificationAlertEvent = "Email_Verification_Alert_Popup"
  public static hireModalEvent = "Hire_Modal"
  public static exploreAllEvent = "Explore_All"

  // all search coaches page with favourite and follow 
  public static favouriteIconEvent = "Added_Favourite"
  public static unfavouriteIconEvent = "Removed_Favourite"
  public static getAllFavouriteEvent = "Get_All_Favourite_Coaches"
  public static getAllFollowEvent = "Get_All_Follow_Coaches"
  public static searchEvent = "Search_Button" /* This  event occures When user clicked on Search btn to search coaches in  contract list */

  // Amplitude events for invoice list
  public static contractDetailPopupEvent = "Detailed_Contract_Popup"
  public static invoiceDetailPopupEvent = "Detailed_Invoice_Popup"  /* This event occures when user click on invoice to get detailed description of invoice */
  public static invoicePaymentBtnEvent = "Pay_Btn"  /* This event Occures when user click on Pay btn to payment then popup opens */
  public static paidBtnEvent = "Pay_Btn_In_Popup" /* final step to pay amount complete */
  public static releaseBtnEvent = "Release_Btn" /* This event Occures when user click on Release btn to payment then popup opens  */
  public static releasedPaymentEvent = "Releas_Payment_Bth_In_Popup" /* final step to release amount complete */
  public static applyPromoCodeEvent = "Clicked_On_Apply_Promotion_Code"

  // Amplitude events for KOACHEE-OFFER-LIST AND KOACHEE-PROPOSAL-LIST
  public static offerAccepEvent = "Offer_Accept_Btn"
  public static offerRejectEvent = "Offer_Reject_Btn"
  public static stripeModalEvent = "Stripe_Modal_Opened"
  public static sendInvoiceEvent = "Send_Invoice_Btn"
  public static contractApproveEvent = "Contract_Approve_Btn"
  public static endContractEvent = "End_Contract_Btn"
  public static endFreeContractBtnEvent = "End_Free_Conract_Btn"
  public static withdrawOfferBtnEvent = "Withdraw_Contract_Btn"
  public static submitReviewBtnEvent = "Submit_Review_Btn"
  public static reHireBtnEvent = "ReHire_Btn"
  public static scheduleSessionBtnEvent = "Schedule_Session_Btn"

  // FEED AMPLITUDE EVENTS
  public static insertCommentEvent = "Insert_Comment"
  public static followEvent = "Follow_Coach"
  public static deletePostEvent = "Delete_Post"
  public static unFollowEvent = "UnFollow_Coach"
  public static publishedPostEvent = "Publish_Post_Btn"
  public static clickedOnImageToShowEvent = "Show_Image"

  // COACH PROFILE AMPLITUDE EVENTS
  public static editBannerImageBtnEvent = "Edit_Banner_Image"
  public static editProfileImageBtnEvent = "Edit_Profile_Image"
  public static shareBtnEvent = "Clicked_On_Share"
  public static shareCopyUrlEvent = "Copied_Share_Url"
  public static EditOverviewEvent = "Edit_Overview_Section"
  public static EditSocialEvent = "Edit_Social_Section"
  public static EditDetailsEvent = "Edit_Details_Section"
  public static EditRatesEvent = "Edit_Rates_Section"
  public static publishedProfileEvent = "Published_Profile"
  public static UnPublishedProfileEvent = "UnPublished_Profile"
  public static onFreeConsultationEvent = "Free_Consultation_On"
  public static offFreeConsultationEvent = "Free_Consultation_Off"
  public static addRatesEvent = "Clicked_On_Add_Rates"
  public static editRatesEvent = "Edit_Added_Rates"
  public static deleteRatesEvent = "delete_Rates"

  public static updateNotificationSetting = "Update_Notification_Setting"

  public static linkAccountEvent = "Link_Account_Btn"
  public static addCalendlyEvent = "Add_Calendly"
  public static updateCalendlyEvent = "Update_Calendly"
  public static addAppointletEvent = "Add_Appointlet"
  public static updateAppointletEvent = "Update_Appointlet"
  public static linkZoomEvent = "Link_Zoom"
  public static linkGoToEvent = "Link_Go_To"
  public static linkGoogleMeetEvent = "Link_Google_Meet"

  public static savePasswordEvent = "Save_Updated_Password"
  public static helpAndSupportEvent = "Help_Support"
  public static bellNotificationEvent = "Notification_Bell"
  public static importContactsEvent = "Import_Contacts"
  public static AddNewContactEvent = "Add_New_Contact"
  public static inviteToKoacheeEvent = "Invite_To_Koachee"
  public static resendInvitationEvent = "Resend_Invitation"
  public static editContactEvent = "Edit_Contact"
  public static viewChatEvent = "View_Chat"
  public static chatStartedEvent = "Chat_Started"

  public static joinCallEvent = "Join_Call"
  public static leaveCallEvent = "Leave_Call"
  public static whiteboardDataEvent ="Whiteboard_Data"
  public static whiteboardEvent = "White_Board"

  public static createNoteEvent = "Create_Note"
  public static allNotesEvent = "All_Notes"
  public static deleteNoteEvent = "Delete_Note"
  public static editNoteEvent = "Edit_Note"

  public static conferencingDataEvent = "Conferencing_Data"
  public static schedulerEvent = "Scheduler"


  // Toast Messages
  public static signUpSuccess = "SignUp Successful";
  public static emailError = "Email is required";
  public static emailValidationError = "Email is not valid";
  public static firstNameError = "First name is required";
  public static lastNameError = "Last name is required";
  public static passwordError = "Password is required";
  public static confirmPasswordError = "Confirm Password is required";
  public static passwordPattrenError = "Password must be atleast 8 characters long. It must also contain atleast 1 uppercase, 1 lowercase, 1 numeric and 1 special characters";
  public static passwordNotMatched = "New and confirm passwords must match";
  public static passwordIsUpdated = "Password is updated";
  // public static noNumbersSpecial = "Numeric and special characters are not allowed in the ";
  public static noNumbersSpecial = "Special characters are not allowed in the ";
  public static emailVerificationNewPending = "A verification link has been sent to your email address. Please click on that link to verify your email address";
  public static emailVerificationOldPending = 'Almost there! Please verify your email address before login';
  public static deletedUser = "Your account has been deleted by Koachee";

  public static signUpWithFB = "Sign up with facebook coming soon";
  public static loginWithFB = "Login with facebook coming soon";
  public static errorTitle = "";
  public static reviewSoon = "Review section coming soon";
  public static userAlreadyExistsMessage = "This user is already exists in the Database";

  public static successTitle = "";
  public static noRecord = "No Record Found";
  public static noData = "No Data Found";
  public static dateNotValid = "Please select valid date range";
  public static addPackageRate = "Please add package rate";
  public static addPackageSession = "Please add package session";
  public static packageAdded = "Package has been added/edited successfully";
  public static packageDeleted = "Package has been deleted successfully";
  //public static noPackageId = "No package id is available";
  public static noPackageId = "Please select rates before sending the hire request.";
  public static noRatesDefined = "No Rate and Package defined by Coach";
  public static emptyUploadImage = "There is no image to upload";
  public static agreeError = "You must agree with Koachee terms and policies";
  public static categoryError = "Please select a Coaching Category";
  public static stripePrimaryKeyError = "Stripe key not provided by koachee";

  public static emptyField = 'All fields are required';
  public static pwdError = 'Old and new password cannot be same';
  public static packageUpdateRes = 'Packages updated Successfully';

  public static emptyFirstName = 'First name is required';
  public static emptyLastName = 'Last name is required';
  public static emptyZipcode = 'Zipcode is required';
  public static emptyHandle = 'Handle is required';
  public static emptyAddress = 'Address is required';
  public static emptyCountry = 'Country is required';
  public static emptyState = 'State is required';
  public static emptyCity = 'City is required';
  public static emptyCoachingCategory = 'Coaching expertise is required';
  public static isRequired = 'is required';
  public static emptyComments = 'Invoice description is required';
  public static invalidInput = 'Invalid Input';


  public static profilePublishedSuccess = 'Profile Published Successfully';
  public static profileUnpublishedSuccess = 'Profile Unpublished Successfully';
  public static requestAccepted = 'Hire request accepted';
  public static requestRejected = 'Hire request refused';
  public static approveEndContract = 'Contract has been ended';
  public static pendingEndContract = 'Contract end request sent successfully';
  public static stripeAccountAddedSuccess = 'Stripe account linked successfully';
  public static copiedToClipboard = 'Copied to clipboard';
  public static locationError = "Koachee is not allowed access to your location. Please enable it in your browser and try location search again";
  public static urlInavlid = 'URL is invalid';
  public static recordsNotFound = 'URL is invalid';
  public static fbInvalid = 'Sign up with this Facebook account is not possible at the moment. Please sign up with a different account';


  // Home Global Variable
  public static homeDataVar: any = [];
  public static currentPage: string = '';

  // Constants for Verfication Page
  public static verificationEmailId: string;
  public static verificationName: string;

  // Hire Coach Variable
  public static closeHire: any = '';

  // Static Token
  public static token: any = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6MSwiRW1haWwiOiJtYW5wcmVldC5icmlsbGlhbmNlaXRAZ21haWwuY29tIiwiaWF0IjoxNTg4NzEwMTA3fQ.xaNmL6hRERRB9DmhrH9afJqa2PshNG4usPSGHW8xLQI';
  public static KoacheeAccountRegion: any = 'US';

  // Page Titles
  public static homeTitle: any = 'Home - Koachee';
  public static messageTitle: any = 'Message - Koachee';
  public static contractTitle: any = 'Contract - Koachee';
  public static invoiceTitle: any = 'Invoice - Koachee';
  public static feedTitle: any = 'Feed - Koachee';
  public static notificationsTitle: any = 'Notifications - Koachee';
  public static advertiseTitle: any = 'Advertise - Koachee';
  public static dashboardTitle: any = 'Dashboard - Koachee';
  public static reportTitle: any = 'Report - Koachee';
  public static networkTitle: any = 'Network - Koachee';
  public static securityTitle: any = 'Security Settings - Koachee';
  public static notificationSettingTitle: any = 'Notification Settings - Koachee';
  public static paymentSettingTitle: any = 'Payment Settings - Koachee';
  public static schedulingTitle: any = 'Scheduling & Conferencing - Koachee';
  public static manageSubscriptionTitle: any = 'Manage Subscription - Koachee';
  public static expertProfileReviewTitle: any = 'Expert Profile Review - Koachee';
  public static loginTitle: any = 'Login - Koachee';
  public static signupTitle: any = 'Sign Up - Koachee';
  public static forgotPasswordTitle: any = 'Forgot Password - Koachee';
  public static verificationTitle: any = 'Verification - Koachee';
  public static videoTitle: any = 'Video - Koachee';
  public static koacheeSuiteTitle: any = 'Koachee Suite - Koachee';
  public static coachingSuiteTitle: any = 'Coaching Suite - Koachee';
  public static conferencingTitle: any = 'Conferencing - Koachee';
  public static koacheeWebspaceTitle: any = 'Koachee Webspace - Koachee';
  public static notesTitle: any = 'Notes - Koachee';
  public static recurringBillingTitle: any = 'Recurring Billing - Koachee';
  public static sharedBoardTitle: any = 'Shared Board - Koachee';
  public static whiteBoardTitle: any = 'WhiteBoard - Koachee';
  public static resourcesTitle: any = 'Resources - Koachee';

  // User Access Consts
  public static addScheduleAccess: any = 'AddSchedule';
  public static noteTemplatesAccess: any = 'NoteTemplates';
  public static exportNotesAccess: any = 'ExportNotes';
  public static recurringPayment: any = 'RecurringPayment';
  public static koacheeWebSpaceAccess: any = 'koacheeWebSpace';
  public static userGrid: any = 'UserGrid';
  public static callRecording: any = 'CallRecording';
  


  public static versionNo: any = '2022.01.24.1014'
}