import { AllNotes } from "../../interfaces/notes";

export class NotepadModel {
    public noteTitle: any;
    public showNotepad: boolean = false;
    public showDeleteNotepad: boolean = false;
    public searchNotes: boolean = false;
    public showEditNotes: boolean = false;
    public resizeNotes: boolean = false;
    public userAccessPopup: boolean = false;
    public templateAccess: boolean = false;
    public msg: AllNotes;
    public currentNoteId: number;
    public description: string = '';
    public createNoteApi: any;
    public getAllNoteApi: any;
    public deleteNoteApi: any;
    public editNoteApi: any;
    public userAccessAPI: any;
}