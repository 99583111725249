<div class="notificationBell">
  <a onclick="return false;" (click)="getAllNotifications('Bell')" role="button" data-toggle="dropdown"
    id="dropdownMenu1" aria-expanded="true" class="notificationBellIcon">
    <span class="fa fa-bell">
    </span>
  </a>

  <ng-container *ngIf="totalCount && totalCount > 0;">
    <span class="badge badge-danger">{{totalCount}}</span>
  </ng-container>
  <ul class="dropdown-menu dropdown-menu-left pull-right" role="menu" aria-labelledby="dropdownMenu1">
    <!-- <li role="presentation">
      <a href="#" class="dropdown-menu-header notificationHeader">Notifications</a>
    </li> -->
    <ngx-loading [show]="loadingNotification" [config]="{}"></ngx-loading>
    <ng-container *ngIf="notificationData != 0 && notificationData != ''; else noNotification">
      <ul class="timeline timeline-icons timeline-sm" style="margin:10px;width:300px">
        <li *ngFor="let notifyData of notificationData">
          <div class="notificationImgSec">
            <div
              *ngIf="notifyData.senderImageUrl != null && notifyData.senderImageUrl != '' && notifyData.senderImageUrl != undefined; else noImage">
              <img src={{notifyData.senderImageUrl}} alt=""  (error)="genricFunc.errorHandler($event)" class="notifyUserImage">
            </div>
            <ng-template #noImage>
              <div>
              <img src="../../../assets/images/female.png" alt="" class="notifyUserImage">
            </div>
              <!-- <span class="noProfileImageHeader notifyUserName">
                {{notifyData.senderUserName | FirstLettersofName}}
              </span> -->
            </ng-template>
          </div>
          <div class="notificationTextSec">
            <span *ngIf="notifyData.EventName == 'OfferSend' && notifyData.Rate > 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} sent you a hire request
              </a>
            </span>
            <!-- Notification for free Consultation -->
            <span *ngIf="notifyData.EventName == 'OfferSend' && notifyData.Rate == 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} sent you a free consultation request
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'OfferAccepted' && notifyData.Rate > 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has accepted your request
              </a>
            </span>
            <!-- Notification for free Consultation -->
            <span *ngIf="notifyData.EventName == 'OfferAccepted' && notifyData.Rate == 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has accepted your free consultation request
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'OfferReject' && notifyData.Rate > 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has refused your request
              </a>
            </span>
            <!-- Notification for free Consultation -->
            <span *ngIf="notifyData.EventName == 'OfferReject' && notifyData.Rate == 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has refused your free consultation request
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'OfferPendingCompletion'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has sent end contract request
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'OfferCompleted'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} approved your request to end the contract
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'OfferWithdrawn' && notifyData.Rate > 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has withdrawn the request
              </a>
            </span>
            <!-- Notification for free Consultation -->
            <span *ngIf="notifyData.EventName == 'OfferWithdrawn' && notifyData.Rate == 0">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has withdrawn the free consultation request
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'InvoiceGenerated'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has sent you an invoice
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'Message'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID,  notifyData.SenderID,notifyData.StatusID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has sent you a message
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'FeedCreated'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has added new feed
              </a>
            </span>
            <!-- Notification for free Consultation -->
            <span *ngIf="notifyData.EventName == 'OfferFreeCompleted'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has ended the free consultation contract
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'LikePost'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has liked your post
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'CommentPost'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has commented on your post
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'Review'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has reviewed you
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'Released'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has released your payment
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'Paid'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has paid your payment
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'Funded'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} has funded your payment
              </a>
            </span>
            <span *ngIf="notifyData.EventName == 'InsertSchedule'">
              <a (click)="updateNotificationReadStatus(notifyData.NotificationID, notifyData.IsRead, notifyData.EventName, notifyData.EventID, notifyData.SenderUserID)"
                [ngClass]="[notifyData.IsRead == false ? 'unreadNotification' : 'readNotification']">
                {{notifyData.senderUserName}} booked a session with you.
              </a>
            </span>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-template #noNotification>
      <span class="col-xs-12 marginBottom1em marginTop1em text-center">No New Notifications</span>
    </ng-template>
  </ul>
</div>