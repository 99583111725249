import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Pages
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

// Constants
import { Constants } from './consts';
import { MaintPageComponent } from './pages/maint-page/maint-page.component';
import { VideoCallComponent } from './pages/messaging/video-call/video-call.component';
import { HomeComponent } from './pages/search/home/home.component';
import { LoaderioComponent } from './pages/loaderio/loaderio.component';
import { SubDomainGuard } from './guard/subDomain/sub-domain.guard';
import { ResourcesComponent } from './pages/resources/resources.component';


// Routes setting
const appRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: Constants.accountPath, loadChildren: () => import('./pages/profile/account/account.module').then(m => m.AccountModule) },
  { path: Constants.coachProfilePath, loadChildren: () => import('./pages/profile/coach/coach.module').then(m => m.CoachModule) },
  { path: Constants.coachMyProfilePath, loadChildren: () => import('./pages/profile/coach/coach.module').then(m => m.CoachModule) }, 
  { path: Constants.profileNotFoundPath, loadChildren: () => import('./pages/profile/profile-not-found/profile-not-found.module').then(m => m.ProfileNotFoundModule) },
  { path: Constants.loginPath, loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule) },
  { path: Constants.signUpPath, loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: Constants.signUpOrgPath, loadChildren: () => import('./pages/auth/sign-up-org/sign-up-org.module').then(m => m.SignUpOrgModule) },
  { path: Constants.coachSignUpPath, loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: Constants.coacheeSignUpPath, loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: Constants.coachListPath, loadChildren: () => import('./pages/search/search-coach-list/search-coach-list.module').then(m => m.SearchCoachListModule) },
  { path: Constants.helpPath, loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule) },
  { path: Constants.completeSignUpAccountPath, loadChildren: () => import('./pages/complete-sign-up-account/complete-sign-up-account.module').then(m => m.CompleteSignUpAccountModule) },
  { path: Constants.coacheeQuestionsPath, loadChildren: () => import('./pages/auth/coachee-questions/coachee-questions.module').then(m => m.CoacheeQuestionsModule) },
  { path: Constants.homePath, loadChildren: () => import('./pages/search/home/home.module').then(m => m.HomeModule) },
  // { path: Constants.homePath, loadChildren: () => import('./pages/search/home/home.module').then(m => m.HomeModule), canActivate: [SubDomainGuard] },
  { path: Constants.forgotPasswordPath, loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: Constants.koacheeOffersListPath, loadChildren: () => import('./pages/koachee-offers-list/koachee-offers-list.module').then(m => m.KoacheeOffersListModule) },
  { path: Constants.koacheeProposalsListPath, loadChildren: () => import('./pages/koachee-proposals-list/koachee-proposals-list.module').then(m => m.KoacheeProposalsListModule) },
  { path: Constants.invoiceListPath, loadChildren: () => import('./pages/invoice-list/invoice-list.module').then(m => m.InvoiceListModule) },
  { path: Constants.settingsPath, loadChildren: () => import('./pages/profile/settings/settings.module').then(m => m.SettingsModule) },
  { path: Constants.allnotificationspath, loadChildren: () => import('./components/messaging/all-notifications/all-notifications.module').then(m => m.AllNotificationsModule) },
  { path: Constants.verificationPath, loadChildren: () => import('./pages/auth/verification/verification.module').then(m => m.VerificationModule) },
  { path: Constants.koacheeProfilePath, loadChildren: () => import('./pages/profile/koachee/koachee.module').then(m => m.KoacheeModule) },
  { path: Constants.koacheeMyProfilePath, loadChildren: () => import('./pages/profile/koachee/koachee.module').then(m => m.KoacheeModule) },
  { path: Constants.IncompleteProfilePath, loadChildren: () => import('./pages/incomplete-profile/incomplete-profile.module').then(m => m.IncompleteProfileModule) },
  { path: Constants.chatPath, loadChildren: () => import('./pages/messaging/chat-messages/chat-messages.module').then(m => m.ChatMessagesModule) },
  { path: Constants.coachesPath, loadChildren: () => import('./pages/search/expertiseLanding/expertiseLanding.module').then(m => m.ExpertiseLandingModule) },
  { path: Constants.dashboardPath, loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: Constants.myNetworkPath, loadChildren: () => import('./pages/network/mynetwork/mynetwork.module').then(m => m.MyNetworkModule) },
  { path: Constants.thankYouPath, loadChildren: () => import('./pages/thank-you/thank-you.module').then(m => m.ThankYouModule) },
  { path: Constants.thankYouCoacheePath, loadChildren: () => import('./pages/thank-you/thank-you.module').then(m => m.ThankYouModule) },
  { path: Constants.feedPath, loadChildren: () => import('./pages/feed/feed.module').then(m => m.FeedModule) },
  { path: Constants.feedUserPath, loadChildren: () => import('./pages/feed/feed.module').then(m => m.FeedModule) },
  { path: Constants.advertisePath, loadChildren: () => import('./pages/advertisePage/advertisePage.module').then(m => m.advertisePageModule) },
  { path: Constants.koacheeSuitePath, loadChildren: () => import('./pages/koachee-suite/koachee-suite.module').then(m => m.KoacheeSuiteModule) },
  { path: Constants.stripeWebHookPath, loadChildren: () => import('./pages/stripe-web-hook/stripe-web-hook.module').then(m => m.StripeWEBHook) },
  { path: Constants.expertProfileReviewPath, loadChildren: () => import('./pages/profile/expert-profile-review/expert-profile-review.module').then(m => m.ExpertProfileReviewModule) },
  { path: Constants.koacheeCompleteProfilePath, loadChildren: () => import('./pages/profile/koahcee-complete-profile/koahcee-complete-profile.module').then(m => m.KoacheeCompleteProfileModule) },
  { path: Constants.videoCall, loadChildren: () => import('./pages/messaging/video-call/video-call.module').then(m => m.VideoCallModule) },
  { path: Constants.schedulePath, loadChildren: () => import('./pages/scheduler/scheduler.module').then(m => m.SchedulerModule) },
  { path: Constants.appointmentsPath, loadChildren: () => import('./pages/appointment/appointment.module').then(m => m.AppointmentModule) },
  { path: Constants.coachingSuitePath, loadChildren: () => import('./pages/coaching-suite/coaching-suite.module').then(m => m.CoachingSuiteModule) },
  { path: Constants.whiteBoardPath, loadChildren: () => import('./pages/white-board/white-board.module').then(m => m.WhiteBoardModule) },
  { path: Constants.loaderIoPath, component: LoaderioComponent },
  { path: Constants.maintaince, component: MaintPageComponent },
  // { path: Constants.shareScreenPath, component: ShareScreenComponent },
  { path: Constants.subDomainPath, loadChildren: () => import('./pages/profile/coach/coach.module').then(m => m.CoachModule) }, 
  { path: Constants.subdomainCoachProfilePath, loadChildren: () => import('./pages/profile/subdomain-coach-profile/subdomain-coach-profile.module').then(m => m.SubdomainCoachModule) },
  // { path: Constants.resourcesPath, component: ResourcesComponent },
  { path: Constants.resourcesPath, loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule) },
  { path: Constants.dashboardReportPath, loadChildren: () => import('./pages/dashboard-report/dashboard-report.module').then(m => m.DashboardReportModule) },

  { path: Constants.homePath, loadChildren: () => import('./pages/search/home/home.module').then(m => m.HomeModule), pathMatch: 'full' },
  // { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}