<div class="notepad-section" *ngIf="(genericFunc.isUserLoggedIn() && genericFunc.getLoggedInUserType() === 'Coach')">
    <div class="plus" (click)="showNotepadForm()">
        <i class="fa fa-plus fa-2x" aria-hidden="true"></i>
    </div>
    <div class="notepadPopup" [ngClass]="[currentModel.resizeNotes ? 'resize' : '']"
        *ngIf="currentModel.showNotepad == true">
        <div class="notepad-navbar">
            <div class="col-xs-8 notepad-title">
                <h4>Notepad</h4>
            </div>
            <div class="col-xs-4 notepad-icons">
                <ul>
                    <li (click)="showResizeNotepadForm(currentModel.resizeNotes)">
                        <i class="fa fa-expand" aria-hidden="true"
                            [ngClass]="[currentModel.resizeNotes ? 'fa-compress' : 'fa-expand']"></i>
                    </li>
                    <li>
                        <i class="fa fa-close close-notepad" (click)="closeNotepad()"></i>
                    </li>
                </ul>
            </div>
        </div>
        <div class="notepad-body">
            <div class="notepad-list" *ngIf="!currentModel.showEditNotes">
                <div class="full-width marginTop1em" *ngIf="currentModel.msg?.length == 0">
                    <h4 class="text-center marginTop2em">
                        You don't have any notes yet.
                      </h4>
                      <h5 class="text-center marginTop2em noNotes">
                        Tip: To start a note, type in the title below and click Create.
                      </h5>
                </div>
                <div class="notepad-messages" *ngIf="currentModel.msg?.length > 0">
                    <div *ngFor="let item of currentModel.msg">
                        <div class="col-xs-7">
                            {{item.NoteTitle}}
                        </div>
                        <div class="col-xs-5">
                            <ul>
                                <li>
                                    <i class="fa fa-pencil" (click)="editNotepad(item)"></i>
                                </li>
                                <li>
                                    <i class="fa fa-trash-o" (click)="showDeleteNotes(item)"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="notepad-edit" *ngIf="currentModel.showEditNotes">
                <input type="text" name="Title" id="Title" placeholder="Title" maxlength="50" class="edit-title"
                    [(ngModel)]="noteTitle">
                <textarea type="text" maxlength="2000" placeholder="Description" name="editNotes"
                    [(ngModel)]="editNotes" class="edit-notes"></textarea>
            </div>
        </div>
        <div class="notepad-bottom">
            <div class="add-msg" *ngIf="!currentModel.showEditNotes">
                <input type="text" class="notepad-msg" maxlength="50" placeholder="Note title here ..."
                    [(ngModel)]="currentModel.noteTitle">
                <button type="button" class="msg-submit" (click)="createNote('General')"
                    [disabled]="!currentModel.noteTitle">Create</button>
                <div class="dropdown" [class.disableTemplates]="!currentModel.noteTitle">
                    <button class="btn blueBtn" type="button" data-toggle="dropdown" (click)="checkUserAccess()"><span
                            class="caret"></span></button>
                    <ul class="dropdown-menu" *ngIf="currentModel.templateAccess" >
                        <li (click)="createNote('General')">General Note</li>
                        <li (click)="createNote('Session')">Session Note</li>
                    </ul>
                </div>
            </div>
            <div class="edit-msg" *ngIf="currentModel.showEditNotes">
                <a class="deleteNotes-cancel linkBtn" (click)="editBackNotepad()">Cancel</a>
                <button type="button" class="edit-msg-save" (click)="addEditNoteDescription()">Save</button>
            </div>
        </div>
    </div>

    <div class="popupBg" *ngIf="currentModel.showDeleteNotepad == true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="submit" class="close" (click)="closeDeleteNotes()">&times;</button>
                    <h4 class="modal-title modalHeader16px">Delete Note</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete your note ?</p>
                </div>
                <div class="modal-footer">
                    <a class="linkBtn" (click)="closeDeleteNotes()">Cancel</a>
                    <button type="submit" class="btn blueBtn" (click)="deleteNote()">Delete</button>
                </div>
            </div>
        </div>
    </div>

    <div class="popupBg" *ngIf="currentModel.userAccessPopup">
        <div class="modal-dialog">
            <div class="modal-content text-left">
                <div class="modal-header">
                    <button type="submit" class="close" (click)="openCloseAccessPopup()">&times;</button>
                    <h4 class="modal-title modalHeader16px">User Access</h4>
                </div>
                <div class="modal-body text-left">
                    <p>Give your notes a consistent structure for your coaching session</p>
                    <p>Upgrade your plan to start using the template feature</p>
                </div>
                <div class="modal-footer">
                    <a class="linkBtn" (click)="openCloseAccessPopup()">Cancel</a>
                    <button type="button" class="btn btnBlue modelBtn"
                        [routerLink]="['/koachee-suite']">Upgrade</button>
                </div>
            </div>
        </div>
    </div>
</div>