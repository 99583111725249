export const environment = {
  production: true,
  baseUrl: "https://portal-stage-apilb.koachee.com", //'http://koacheeapistage-env.eba-fw5z259w.us-east-2.elasticbeanstalk.com',//"http://koacheeapiaws-env-3.eba-d2d3y3yp.us-east-1.elasticbeanstalk.com",//api url
  googleClientId:
    "548543286321-oj89rpe1csd8l7il5gf3p4u6mu765aco.apps.googleusercontent.com",
  angularSiteURL: "https://portal-stage.koachee.com/", // web url
  isAmplitudeActive: true, // web url
  stripeUrl:
    "https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_I4L2KBjv9FbVeeCIJAYLTY6IH192uejL&scope=read_write",
  facebookClientId: "2743090805781672",

  // Enable Maintaince Page
  enableMaintaince: false,

  // agora video call & whiteboard
  videoCallAppID: "03a4c03594094f9db0c39b48c92a4368",
  whiteboardAppIdentifier: "fByu8HOnEeysvrM9YOgM8g/8n7O89X3B0j7FA",
};
