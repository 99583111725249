import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserObject } from '../../pages/feed/feed.model';

@Pipe({
    name: 'UserLink'
})
export class UserLinkPipe implements PipeTransform {

    transform(value: any, args: { users: UserObject[] }): any {
        args.users.forEach((mentionedUser,index) => {
            const mentionedName = '@' + mentionedUser.name;
            value = value.replace(new RegExp(mentionedName), (url: string) => {
                return `<a target="_blank" href="${environment.angularSiteURL}coach/${mentionedUser.Handle}">${mentionedUser.name}</a>`;
            })
        })
        return value
    }
}