import { Component, OnInit } from '@angular/core';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { ToasterService } from '../../services/toaster/toaster.service';

import { Constants } from '../../consts';

@Component({
  selector: 'app-header-email-verification',
  templateUrl: './header-email-verification.component.html',
  styleUrls: ['./header-email-verification.component.css']
})
export class HeaderEmailVerificationComponent implements OnInit {
  public userEmail: any;
  public userName: any;
  public resendVerifyEmailAPI: any;
  public loading: boolean = false;
  public isEmailSend: boolean = false;
  constructor(
    public apiManager: ApiManagerComponent,
    private genricFunc: GenericFunctionsComponent,
    private toast: ToasterService,

  ) { }

  ngOnInit() {
    this.userEmail = this.genricFunc.getLoggedInEmailID()
    this.userName = this.genricFunc.getLoggedInUserName();

  }

  generateLink() {
    var data = {
      "emailid": this.userEmail,
      "name": this.genricFunc.getLoggedInFirstName()
    };
    this.loading = true;
    this.apiManager.postMethod(Constants.resendVerifyEmail, false, data).subscribe((apiResponse) => {
      this.loading = false
      this.isEmailSend = true
      if (apiResponse.response.data == "Email Sent") {
        // this.showSendButton = true;
        this.toast.success("Email Sent");
        // this._router.navigateByUrl(Constants.loginPath);
      }
    })
  }


  ngOnDestroy() {
    this.loading = false;
    if (this.resendVerifyEmailAPI) {
      this.resendVerifyEmailAPI.unsubscribe();
    }
  }

}
