import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../../../consts';
import { AllNotes, DeleteNote, EditDescription, ExportAllNotes, Notes } from '../../../interfaces/notes';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { GenericFunctionsComponent } from '../../../providers/generic-functions/generic-functions';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class CreateNoteService {

  constructor(
    private apiManager: ApiManagerComponent,
    private genericFunc: GenericFunctionsComponent,
  ) { }

  public createNote(body: Notes): Observable<Notes> {
    return this.apiManager.postMethod(Constants.createNote, false, body).pipe(map((data) => {
      return data
    }))
  }

  getAllNotes(body: AllNotes): Observable<AllNotes> {
    return this.apiManager.postMethod(Constants.getAllNotes, true, body).pipe(map((data) => {
      return data
    }));
  }

  deleteNote(body: DeleteNote): Observable<DeleteNote> {
    return this.apiManager.postMethod(Constants.deleteNote, true, body).pipe(map((data) => {
      return data
    })); 
  }

  addEditNoteDescription(body: EditDescription): Observable<EditDescription> {
    return this.apiManager.postMethod(Constants.addEditNoteDescription, true, body).pipe(map((data) => {
      return data
    }));
  }

  exportAllNotes(body: ExportAllNotes): Observable<ExportAllNotes> {
    return this.apiManager.postMethod(Constants.exportNotes, true, body).pipe(map((data) => {
      return data
    }));
  }

}
