<div class="container">
  <nav class="navbar navbar-default headerSec">
      <div class="container-fluid">
          <div class="navbar-header">
              <img src="https://portal-stage.koachee.com/assets/images/koacheeLogo.png" height="30">
          </div>
      </div>
  </nav>
  <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mainDataSec">
          <h1>
              Scheduled Maintenance In Progress
          </h1>
          <div>Koachee is currently undergoing its scheduled maintenance.</div>
          <!-- <div><b>We will be back at 1:00 AM EST.</b></div> -->
          <div>We apologize for any inconvenience this may cause. however, on the bright side, we will be
              back
              bigger, better, and faster.</div>
          <div>Thank you,</div>
          <div>Team Koachee.</div>
      </div>
  </div>
</div>