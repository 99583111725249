import { SocialUser } from 'angularx-social-login';

import { Constants } from '../../consts';
export class loginModel{
    sub: any;
    public loading = false;
    public user: SocialUser;
    private loginEmailVal: any;
    private loginPasswordVal: any;
    public loginEmail: any;
    public loginPassword: any;
    public setUserProfile: any;
    public errorMessage: any = false;
    public emailValidation: any;
    dummyObject: Object;
    array: any = [];
    public isNeedToCheckLoginGoogle: Boolean = false
    public isNeedToCheckLoginFacebook: Boolean = false
    public currentLoginProvider: any = ''
    public  loggedIn: boolean;
    public  passwordShow: boolean = false;
    public projectName = Constants.projectName;
    public loginWithSocialAPI: any;
    public getLoginDataAPI: any;
}