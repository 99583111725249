import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    constructor(private title: Title, private meta: Meta) { }
    updateTitle(title: string) {
        this.title.setTitle(title);
        this.meta.updateTag({ name: 'name', content: title })
        this.meta.updateTag({ name: 'author', content: title })
        // this.meta.updateTag({ property:'twitter:card', content: 'summary_large_image' })
        this.meta.updateTag({ property: 'og:title', content: title })
        this.meta.updateTag({ name: 'title', content: title })
        this.meta.updateTag({ property: 'og:platform', content: title })
        this.meta.updateTag({ name: 'twitter:title', content: title })
    }
    updateImageUrl(url: string) {
        this.meta.updateTag({ property: 'og:image', content: url })
        this.meta.updateTag({ name: 'twitter:image', content: url })   
        this.meta.updateTag({ property: 'og:image:width', content: "500" })
        this.meta.updateTag({ property: 'og:image:height', content: "500" })
    }

    updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc })
        this.meta.updateTag({name: 'description' , property: 'og:description', content: desc })
        this.meta.updateTag({ name: 'twitter:description', content: desc })
    }
    updateType(type: string) {
        this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' })
        this.meta.updateTag({ property: 'og:type', content: type })
    }
    updateUrl(url: string) {
        this.meta.updateTag({ property: 'og:url', content: url })
        this.meta.updateTag({ property: 'twitter:url', content: url })
        this.meta.updateTag({ name: 'twitter:site', content: url })
    }
  
}
