import { Injectable } from '@angular/core';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { Constants } from '../../../consts';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable()
export class HomeService {

  constructor(
    private apiManager: ApiManagerComponent,
  ) { }

  getHomeData(handle: string): Observable<any> {
    const timestamp = new Date().getTime(); 
    return this.apiManager.getMethod(`${Constants.getHomeData+handle}?timestamp=${timestamp}`, true).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getStyleData(handle: string): Observable<any> {
    return this.apiManager.getMethod(Constants.getStyleData + handle, true).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getMethodWithoutToken(handle: string): Observable<any> {
    return this.apiManager.getMethodWithoutToken(Constants.getMethodWithoutToken+handle, true).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

}
