import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { Routes, RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../modules/shared/shared.module';
import { SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { environment } from '../../../../environments/environment';
// import { HttpClient } from '@angular/common/http';

// Social Login Settings with api
let providers = {
  "google": {
    "clientId": environment.googleClientId
  },
  "facebook": {
    "clientId": environment.facebookClientId
  },
};

const CONFIG = {
  autoLogin: false,
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleClientId)
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookClientId)
    }
  ]
} as SocialAuthServiceConfig;

export function provideConfig() {
  return CONFIG;
}


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    // HttpClient,
    // SocialLoginModule,
    NgxLoadingModule,
    SharedModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule { }
