import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { LocalStorageComponent } from './providers/local-storage/local-storage';
import { Constants } from './consts';
import { ShareDataProviderComponent } from './providers/share-data-provider/share-data-provider.component';
// import { ApiManagerComponent } from './providers/api-manager/api-manager';
import { ApiManagerComponent } from './providers/api-manager/api-manager';
import { AppState } from './store/app.state';
import { Store } from '@ngrx/store';
import { SubDomainService } from './services/subDomain/sub-domain.service';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
	public title = 'Koachee';
	public route: string;
	public activeSubDomain: boolean = false;
	public checkApiCall: boolean = false;

	constructor(
		public _router: Router,
		public localStore: LocalStorageComponent,
		// private apiManager: ApiManagerComponent,
		public sharedData: ShareDataProviderComponent,
		public activatedRoute: ActivatedRoute,
		private store: Store<AppState>,
		private subDomainSer: SubDomainService,
		public apiManager: ApiManagerComponent,
		public location: Location,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
		
		//this._router.navigateByUrl(Constants.maintaince)
	}

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			// this._router.navigateByUrl(Constants.maintaince)
			this.mainPages()

			// this.apiManager.getMethodWithoutToken('https://api.npoint.io/6a7fa496f07101330f23', false).subscribe((res: any) => {
			// 	if(res.maintStatus) {
			// 		this._router.navigateByUrl(Constants.maintaince)
			// 	} else {
			// 		this.mainPages()
			// 	}
			// }, error => {
			// 	this.mainPages()
			// })
		}
	}

	ngAfterViewInit() {
		if(isPlatformBrowser(this.platformId)){
			this._router.events.subscribe((evt: any) => {
				if (!(evt instanceof NavigationEnd)) {
					return;
				}
				window.scrollTo(0, 0)
			});
		}
	}

	mainPages() {
		if (this.localStore.getProfile() == undefined) {
			this.getURLPath();
			this.switchCaseFunc();
		} else {
			this.getURLPath();
			this._router.navigateByUrl(this.route);
		}
	}

	getURLPath() {
		if (this.location.path() != '') {
			this.route = this.location.path();
		} else {
			this.route = '/' + Constants.homePath;
		}
	}

	switchCaseFunc() {
		var mainRoute = this.route;
		// if condition added due to coach, forgot-password, verification and expertise is coming with dynamic id rest of the things will work in switch case
		if (mainRoute.search('forgot-password') != -1) {
			this._router.navigateByUrl(mainRoute)
		} else if (mainRoute.search('verification') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('/coaches/') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('stripeWebHook') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('/signup?') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('loaderio-cb76aee95a80eec06042ea930f456b20') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('/coachsignup') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('/coacheesignup') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('/coach-list') != -1) {
			this._router.navigateByUrl(mainRoute)
		}
		else if (mainRoute.search('/coach/') != -1) {
			this._router.navigateByUrl(this.route);
		} else if (mainRoute.search('/complete-signup-account/') != -1) {
			this._router.navigateByUrl(this.route);
		} else if (mainRoute.search('/feed') != -1) {
			this._router.navigateByUrl(mainRoute);
		} else {
			switch (this.route) {
				case '/' + Constants.loginPath:
					this._router.navigateByUrl(this.route); break;
				case '/' + Constants.signUpPath:
					this._router.navigateByUrl(this.route); break;
				// case '/' + Constants.completeSignUpAccountPath:
				// 	this._router.navigateByUrl(this.route); break;
				default:
					this._router.navigateByUrl(Constants.homePath);
					break;
			}
		}
	}

}
