import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Constants } from '../../consts';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { subDomainUpdate } from '../../state/subDomain/subDomain.actions';
import { AppState } from '../../store/app.state';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';

@Injectable({
  providedIn: 'root'
})
export class SubDomainService implements OnInit {

  constructor(
    private store: Store<AppState>,
    public apiManager: ApiManagerComponent,
    public genericFunc: GenericFunctionsComponent,
    public _router: Router,
  ) { }

  ngOnInit(): void {
    this.checkSubDomain();
  }

  checkSubDomain() {
    let enableSub = true;
    let mainUrl = window.location.href;
    // let mainUrl = "https://jeevan.koachee.com"
    let domainName = this.genericFunc.getSubDomain()

    const substring = "localhost:";

    if ((domainName) && (!domainName[0].includes(substring))) {
      if ((domainName[0] !== "portal") && (domainName[0] !== "portal-stage") && (domainName[0] !== "pre-prod")) {
        let data = {
          "Handle": domainName[0]
        }
        this.apiManager.postMethodWithoutToken(Constants.checkUserSubDomain, false, data).subscribe((jsonData: any) => {
          if (jsonData) {
            if (jsonData.status == 1) {
              if (jsonData.response) {
                if (jsonData.response.data === "true") {
                  enableSub = true;
                } else {
                  enableSub = false;
                }
                let domainData = {
                  enableSubDomain: enableSub,
                  apiCall: true,
                }
                this.store.dispatch(subDomainUpdate(domainData));
                if (!enableSub) {
                  this._router.navigateByUrl(Constants.profileNotFoundPath);
                }
              }
            }
          }
        }, (error: any) => {
          enableSub = false;
        });
      } else {
        enableSub = false;
      }
    } else {
      enableSub = false;
    }
    return enableSub;
  }
}
