import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';

// Pages
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

// Components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// External Sources
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SocialLoginModule } from 'angularx-social-login';
import { provideConfig } from './pages/auth/login/login.module';
import { LocalStorageComponent } from './providers/local-storage/local-storage';
import { ShareDataProviderComponent } from './providers/share-data-provider/share-data-provider.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaintPageComponent } from './pages/maint-page/maint-page.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiManagerComponent } from './providers/api-manager/api-manager';
import { SocketServiceService } from './services/socket/socket-service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SEOService } from './providers/seo-manager/seo-managers';
import { GetMetaService } from './providers/seo-manager/get-meta-service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {TransferHttpCacheModule} from '@nguniversal/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { appReducer } from './store/app.state';
import { SUB_DOMAIN_STATE_NAME } from './state/subDomain/subDomain.selectors';
import { subDomainReducer } from './state/subDomain/subDomain.reducer';
import { ProfileNotFoundComponent } from './pages/profile/profile-not-found/profile-not-found.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    MaintPageComponent,
    ProfileNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SocialLoginModule,
    AppRoutingModule,
    TransferHttpCacheModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({
      backdropBorderRadius: '0px',
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.7)',
      fullScreenBackdrop: true
    }),
    StoreModule.forRoot(appReducer),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }),
    StoreModule.forFeature(SUB_DOMAIN_STATE_NAME, subDomainReducer),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideConfig
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    LocalStorageComponent,
    ApiManagerComponent,
    GetMetaService,
    SocketServiceService,
    ShareDataProviderComponent,
    SEOService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  exports:[]
})
export class AppModule {

 }