import { SubDomainState } from './subDomain.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const SUB_DOMAIN_STATE_NAME = 'subDomain';

const getSubDomainState = createFeatureSelector<SubDomainState>(SUB_DOMAIN_STATE_NAME);

export const getSubDomain = createSelector(getSubDomainState, (state) => {
  return state;
});
