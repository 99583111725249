import { Injectable } from '@angular/core';

@Injectable({
  providedIn:'root'
})
export class ShareDataProviderComponent {
  public signUpStorage: any;
  public chatMessageUserData: any;
  public tradionalSignUpData: any;
  public value:String;
  public forgetValue:String;
  public notificationCount:String;
  public verificationCode:any;
  public verificationEmailId:any;
  public verificationName:any;
  public withoutLoginUserData:any;
  public withoutLoginType:any;
  public homeDataVar:any;
  public contractUserDetails:any;
  public contractPaymentHistory:any;
  public showContractDetailModal:boolean = false;
  public alreadyHired:boolean = false;
  public alreadyHiredPopup:boolean = true;
  public alreadyHiredDemoPopup:boolean = true;
  public rehireData:any;
  public showInvoiceDetailModal:boolean = false
  public singlePackageData:any = []
  public multiplePackageData:any = []
  public freeConsultationData:any = []
  public seperateData:any = [] 
  public singlePackage:any = [];
  public multiplePackage:any = [];  
  public freeConsultData:any = [];  
  public singleData:any;
  public showNonEditableModal:boolean = false
  public viewingAs: string = '';
  public seoTag: seoTag;
  public audioUpdate: boolean;
  public videoUpdate: boolean;
  public cameraLeft: boolean = false;
  public subDomainApiCall:boolean = false;
  public constructor() { }
}
export interface seoTag {
  name: string,
  description: string,
  image: string,
  url: string,
  headline:string
}