import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class GetMetaService {
    constructor(
        private http: HttpClient
    ) {}

    getConfig(handle:string): Observable<any> {        
        return this.http.get(environment.baseUrl + "/router/seoTags?Handle="+handle, { headers: null }).pipe(map((response) => {
            return response
        }))
    }
}
