import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';

@Component({
  selector: 'app-loaderio',
  templateUrl: './loaderio.component.html',
  styleUrls: ['./loaderio.component.css']
})
export class LoaderioComponent implements OnInit {

  constructor(
    public genericFunc: GenericFunctionsComponent
  ) {
    window.open(environment.angularSiteURL+ "assets/loaderio-cb76aee95a80eec06042ea930f456b20.txt", "_blank")
    this.genericFunc.downLoadFile("loaderio-cb76aee95a80eec06042ea930f456b20", "text/plain;charset=utf-8", "loaderio-cb76aee95a80eec06042ea930f456b20.txt");
  }

  ngOnInit(): void {
  }

}
