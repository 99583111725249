import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Constants } from '../../consts';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { LocalStorageComponent } from '../../providers/local-storage/local-storage';
import { CreateNoteService } from '../../services/notepad/createNote/create-note.service';
import { NotepadModel } from './notepad.model';
import { UserAccessControl } from '../../services/userAccess/userAccess.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.css'],
  providers: [NotepadModel, UserAccessControl]
})
export class NotepadComponent implements OnInit {
  loading: boolean = false;
  title: string = '';
  searchText: any = '';
  noteTitle: any;
  public editNotes: any;
  constructor(
    private createNoteService: CreateNoteService,
    public currentModel: NotepadModel,
    public localStore: LocalStorageComponent,
    private toast: ToasterService,
    public reload: ChangeDetectorRef,
    public genericFunc: GenericFunctionsComponent,
    public userAccessService: UserAccessControl
  ) {
    if (this.localStore.getNotePad()) {
      this.openNotePad()
    }
    if (this.localStore.getResizeNotePad()) {
      this.resizeNotepad(false);
    }
  }

  ngOnInit(): void {
  }

  closeNotepad() {
    this.localStore.clearNotePad();
    this.localStore.clearResizeNotePad();
    this.currentModel.showNotepad = false;
  }

  showNotepadForm() {
    this.localStore.setNotePad('true')
    this.openNotePad();
  }

  showResizeNotepadForm(resize: boolean) {
    if (resize) {
      this.localStore.clearResizeNotePad()
    } else {
      this.localStore.setResizeNotePad('true')
    }
    this.resizeNotepad(resize);
  }

  openNotePad() {
    this.loading = true;
    this.currentModel.showNotepad = true;
    this.getAllNotes()
  }

  showDeleteNotes(item: any) {
    this.currentModel.currentNoteId = item.ID
    this.currentModel.showDeleteNotepad = true;
  }

  closeDeleteNotes() {
    this.currentModel.showDeleteNotepad = false;
  }

  searchTab() {
    this.currentModel.searchNotes = true;
  }

  closeSearchTab() {
    this.currentModel.searchNotes = false;
  }

  editNotepad(item: any) {
    this.currentModel.currentNoteId = item.ID;
    this.currentModel.noteTitle = item.NoteTitle;
    this.currentModel.description = item.NoteDescription;
    this.noteTitle = this.currentModel.noteTitle;
    this.editNotes = this.currentModel.description
    this.currentModel.showEditNotes = true;
  }

  editBackNotepad() {
    this.currentModel.noteTitle = "";
    this.getAllNotes();
    this.currentModel.showEditNotes = false;
  }

  resizeNotepad(resize: boolean) {
    this.loading = true;
    this.currentModel.resizeNotes = !resize;
  }

  createNote(type: string): void {
    this.genericFunc.amplitudeFunc(Constants.createNoteEvent)
    let templateData = (type === 'General' ? '' : 'Key accomplishments since the last session:\n\nOutcome or goal for this session:\n\nGeneral notes:\n\nKey Resources:\n\nAccountability steps or next steps:\n\n');
    let data = {
      NoteTitle: this.currentModel.noteTitle,
      NoteDescription: templateData
    }
    this.currentModel.createNoteApi = this.createNoteService.createNote(data)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data.response) {
              if (data.response.data) {
                this.noteTitle = this.currentModel.noteTitle;
                this.currentModel.currentNoteId = data.response.data[0].notePadID;
                this.editNotes = templateData;
                this.currentModel.showEditNotes = true;
                this.reload.detectChanges();
              }
            }
          }
        },
        (error) => console.log(error)
      );
  }

  getAllNotes(): void {
    this.genericFunc.amplitudeFunc(Constants.allNotesEvent)
    let data = {
      NoteTitle: this.currentModel.noteTitle,
      NoteDescription: this.currentModel.description
    }
    this.currentModel.getAllNoteApi = this.createNoteService.getAllNotes(data)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data.response) {
              if (data.response.data) {
                this.currentModel.msg = data.response.data;
                this.reload.detectChanges();
              }
            }
          }
        },
        (error) => console.log(error)
      );
  }

  deleteNote(): void {
    this.genericFunc.amplitudeFunc(Constants.deleteNoteEvent)
    let data = {
      requestorUserID: this.genericFunc.getLoggedInUserID(),
      noteID: this.currentModel.currentNoteId,
    }
    this.currentModel.deleteNoteApi = this.createNoteService.deleteNote(data)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data.response) {
              this.closeDeleteNotes();
              this.getAllNotes();
            }
          }
        },
        (error) => console.log(error)
      );
  }

  addEditNoteDescription(): void {
    this.genericFunc.amplitudeFunc(Constants.editNoteEvent)
    let data = {
      requestorUserID: this.genericFunc.getLoggedInUserID(),
      noteID: this.currentModel.currentNoteId,
      NoteTitle: this.noteTitle,
      NoteDescription: this.editNotes
    }
    this.currentModel.editNoteApi = this.createNoteService.addEditNoteDescription(data)
      .subscribe(
        (data: any) => {
          if (data) {
            if (data.response) {
              this.editBackNotepad();
              this.currentModel.noteTitle = "";
              this.getAllNotes();
              this.reload.detectChanges();
            }
          }
        },
        (error) => console.log(error)
      );
  }

  checkUserAccess() {
    this.loading = true;
    let data = {
      "UserId": this.genericFunc.getLoggedInUserID(),
      "EventName": Constants.noteTemplatesAccess
    }
    this.currentModel.userAccessAPI = this.userAccessService.userAccessControl(data).subscribe((jsonData: any) => {
      this.loading = false;
      if (jsonData) {
        if (jsonData.status == 1) {
          if (jsonData.response) {
            if (jsonData.response.data === "true") {
              this.currentModel.templateAccess = true;
            } else {
              this.openCloseAccessPopup();
            }
          }
        }
      }
    }, (error: any) => {
      this.loading = false;
      this.toast.error(error, Constants.errorTitle);
    });
  }

  openCloseAccessPopup() {
    this.currentModel.userAccessPopup = !this.currentModel.userAccessPopup;
  }

  ngOnDestroy() {
    this.loading = false;
    if (this.currentModel.createNoteApi) {
      this.currentModel.createNoteApi.unsubscribe();
    }
    if (this.currentModel.getAllNoteApi) {
      this.currentModel.getAllNoteApi.unsubscribe();
    }
    if (this.currentModel.deleteNoteApi) {
      this.currentModel.deleteNoteApi.unsubscribe();
    }
    if (this.currentModel.editNoteApi) {
      this.currentModel.editNoteApi.unsubscribe();
    }
    if (this.currentModel.userAccessAPI) {
      this.currentModel.userAccessAPI.unsubscribe();
    }
  }

}
