import { Component, ApplicationRef, AfterContentInit, PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Constants } from '../../../consts';
import { Router } from '@angular/router';
import { LocalStorageComponent } from '../../../providers/local-storage/local-storage';
import { GenericFunctionsComponent } from '../../../providers/generic-functions/generic-functions';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { ToasterService } from '../../../services/toaster/toaster.service';
import { SocketServiceService } from '../../../services/socket/socket-service.service';
import { BehaviorSubject } from 'rxjs';
import { CusHeader1Component } from '../../../components/cus-header1/cus-header1.component';
import { HomeService } from '../../../services/search/home/home.service';
import { ShareDataProviderComponent } from '../../../providers/share-data-provider/share-data-provider.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HomeService]
})
export class HomeComponent implements AfterContentInit {
  messageText: string;
  messages: Array<any>;
  public loading = false;
  public notificationText = '';
  public notificationBarStatus: boolean = false;
  public currentUserID: any;
  public badgeCountNumber: any;
  public socketMsgData: any;
  public href: any;
  public unreadMessagesCount: number = 0;
  public badgeCounter = new BehaviorSubject<Number>(0);
  public unreadMessageCounter = new BehaviorSubject<Number>(0);
  public totalCount: number = 0;
  public selected: any;
  public currentCoachData: any;
  public loggedInUserData: any;
  public currentCoachDataLoaded = false;
  public featuredCoach: any = [];
  public lastSearchCoachs: any = [];
  public sponsoredCoachs: any = [];
  public allSkills: any = [];
  public currentCoachAdded = false;
  public selectredRow: any = '';
  public activeRow: any;
  public currentSliderRow: any;
  public isLoggedIn: any = false;
  public currentHomeData: any;
  public showPublishProfilePopup: boolean = false;
  public showPublishProfilePopupOneTime: boolean = false;
  public popupData: any;
  public popupDataStatus: any;
  public showPublishMsg: boolean = false;
  public clickedOnSameCard: boolean = false;
  public onMobile: boolean = false;
  public setStyle: boolean = false;
  public showSkills: any;
  public deviceInfo: any;
  public getHomeDataWithLogin: any;
  public getHomeDataWithOutLogin: any;
  public homeHtml: any;

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    autoplay: false,
    slidesPerGroup: 3,
    slidesPerView: 3,
    breakpoints: {
      1366: {
        slidesPerGroup: 3,
        slidesPerView: 3
      },
      1024: {
        slidesPerGroup: 2,
        slidesPerView: 2
      },
      767: {
        slidesPerGroup: 2,
        slidesPerView: 2
      },
      600: {
        slidesPerGroup: 1,
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false
  };

  public showPageNotFound: boolean = false
  public response: any
  isBrowser
  constructor(
    private _router: Router,
    private localStore: LocalStorageComponent,
    private genericFunc: GenericFunctionsComponent,
    private apiManager: ApiManagerComponent,
    private ref: ApplicationRef,
    private deviceService: DeviceDetectorService,
    private toast: ToasterService,
    public socketInstance: SocketServiceService,
    public shareUserData: ShareDataProviderComponent,
    public headerSec: CusHeader1Component,
    public HomeService: HomeService,
    private titleService: Title,
		@Inject(PLATFORM_ID) private platformId: Object,

  ) {
    // this.genericFunc.isUserLoggedIn();
    //this.localStore.clearNotification();
    this.isBrowser = isPlatformBrowser(this.platformId)
    if(isPlatformBrowser(this.platformId)){
      this.messages = new Array();
      this.currentUserID = this.genericFunc.getLoggedInUserID();
      if ((this.genericFunc.getLoggedInUserType() == '') || (this.genericFunc.getLoggedInUserType() == '') || (this.genericFunc.getLoggedInUserType() == '')) {
        // this._router.navigateByUrl(Constants.completeSignUpAccountPath);
      }
  
      this.deviceInfo = this.deviceService.getDeviceInfo();
      if (this.deviceInfo.device == 'Unknown') {
        this.onMobile = false;
      } else {
        this.onMobile = true;
      }
      this.genericFunc.amplitudeFunc(Constants.homeEvent);
      this.titleService.setTitle(Constants.homeTitle);
    }
  
  }

  ngAfterContentInit() {
  
    if(isPlatformBrowser(this.platformId)){
      this.getFulHomeData();
      this.isLoggedIn = this.genericFunc.isUserLoggedIn();
      this.genericFunc.resetUserWithoutLogin()
      // if(this.genericFunc.isUserLoggedIn()) {
      //   this.genericFunc.helpHeroFunc();
      // }
    }
   
  }

  socketAfterApiCall() {
    this.configureSocket();
    this.socketBroadcastSettings();
  }

  ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }



  async select(index: any, coachData: any, currentRow: any) {
    this.genericFunc.amplitudeFunc(Constants.cardClickedEvent)
    if ((this.selected == index) && (this.currentSliderRow == currentRow) && (this.clickedOnSameCard == false)) {
      this.clickedOnSameCard = true;
      this.selectredRow = '';
    } else {
      this.selected = index;
      this.currentCoachData = coachData;
      this.currentSliderRow = currentRow;
      this.selectredRow = currentRow;
      this.clickedOnSameCard = false;
      this.showSkills = this.genericFunc.getUserSkill(coachData.Expertise)
      this.ref.tick();
      await this.addView()
    }
  };

  async addView() {
    let data = {
      "userId": this.currentCoachData.ID,
    }
    if (this.currentUserID != '' && this.currentUserID != null && this.currentUserID != undefined) {
      this.response = await this.apiManager.postMethod(Constants.addView, true, data).subscribe((response) => {
        // return response
      })
    }
    else {
      this.response = await this.apiManager.postMethodWithoutToken(Constants.addViewWithoutToken, true, data).subscribe((response) => {
        // return response
      })
    }
  }



  // getUserSkill(val:any){
  //   this.showSkills = '';
  //   const skillData =  val.Expertise.toString().split(',');
  //   let userSkills = skillData.sort()

  //   let allSkills = this.localStore.getSkills()
  //   let count = 0;

  //   allSkills.forEach((skill: any) => {
  //     if (userSkills[count] == skill.id) {
  //       if (count == 0) {
  //         this.showSkills = skill.itemName
  //       } else {
  //         this.showSkills = this.showSkills + ',' + skill.itemName
  //       }
  //       count++
  //     }
  //   });
  // }
  completePublishProfile() {
    this.closePublishPopup()
    this._router.navigateByUrl(Constants.settingsPath)
  }

  isActive(index: any, currentRow: any) {
    if (this.clickedOnSameCard == true) {
      return '';
    } else {
      if (this.currentSliderRow == currentRow) {
        return this.selected === index;
      } else {
        return '';
      }
    }
  };

  socketBroadcastSettings() {
    this.socketInstance.broadCastSocket.subscribe(
      value => {
        if (value) {
        }
      }
    );
  }


  configureSocket() {
    this.socketInstance.connectSocket();
  }

  showNotificationBar() {
    this.notificationBarStatus = true;
  }

  setNotification(msg: any = 'You have recieved new notification !!') {
    this.notificationText = msg;
    this.showNotificationBar();
  }

  closeNotificationBar() {
    this.notificationBarStatus = false;
  }

  getFulHomeData() {
    let domainName = this.genericFunc.getSubDomain()
    this.currentCoachDataLoaded = false;
    this.currentCoachAdded = false;
    this.loading = true;
    if (this.currentUserID != '' && this.currentUserID != null && this.currentUserID != undefined) {
      this.getHomeDataWithLogin = this.HomeService.getHomeData(domainName[0]).subscribe((jsonData) => {
        this.loading = false;
        if (jsonData != undefined) {
          if (jsonData.response != undefined) {
            if (jsonData.response.data != undefined) {
              if (jsonData.response.data.userData != undefined) {
                this.loggedInUserData = jsonData.response.data.userData
                this.localStore.setKoacheeSuitePlan(this.loggedInUserData.koacheeSuitePlanID)
                if (jsonData.response.data.userData.StatusID == -1) {
                  this.headerSec.logOut()
                  this.toast.error(Constants.deletedUser)
                  // this.showPageNotFound = true
                }
                else {
                  this.genericFunc.switchOffLoader(this.loading);
                  this.homeData(jsonData, true);
                }
              }
            }
          }
        }
       this.getStyleData()

        // this.setSkillsWithoutLogin()
      })
    } else {
      this.getHomeDataWithOutLogin = this.HomeService.getMethodWithoutToken(domainName[0]).subscribe((jsonData) => {
        // this.genericFunc.setLoggedInUserStatus(Constants.key, jsonData.response.data.userData);
        this.loading = false;

        if (jsonData != undefined) {
          if (jsonData.response != undefined) {
            if (jsonData.response.data != undefined) {
              if (jsonData.response.data.userData != undefined) {
                if (jsonData.response.data.userData.StatusID == -1) {
                  this.headerSec.logOut()
                  this.toast.error(Constants.deletedUser)
                  // this.showPageNotFound = true
                }
                else {
                  this.genericFunc.switchOffLoader(this.loading);
                  this.homeData(jsonData, false);
                  this.setSkillsWithoutLogin(jsonData.response.data.allSkillsCategories)
                }
              }
            }
          }
        }
        this.getStyleData()

      })
    }

  }

  gotoExpertise(val: any) {
    this.genericFunc.amplitudeFunc(Constants.exploreAllEvent + '-' + val)
    this._router.navigateByUrl('coaches/' + val);
  }

  homeData(jsonData: any, checkStatus: Boolean) {
    this.socketAfterApiCall();

    if (jsonData.response) {

      Constants.homeDataVar = jsonData.response.data;
      let fullDashData = Constants.homeDataVar;

      if (jsonData.status == 1) {

        if (jsonData.response.data) {
          if (jsonData.response.data.refreshToken) {
            this.localStore.setToken(jsonData.response.data.refreshToken);
          }
        }

        if (checkStatus) {

          if (jsonData.response.data.userData) {
            let user = jsonData.response.data.userData
            if (user.StatusID == 5 && user.Type == "") {
              this._router.navigateByUrl(Constants.completeSignUpAccountPath);
              return
            }
            //  else if (user.StatusID == 4) {

            //   this.toast.success(Constants.emailVerificationOldPending)
            //   this._router.navigateByUrl(Constants.loginPath);
            //   //move to login page with email pending verification
            //   return
            // }
          }
        }
        if (jsonData.response.data.notificationCount) {
          this.totalCount = jsonData.response.data.notificationCount.totalCount;
          // this.badgeCounter.next(this.totalCount);
          this.genericFunc.setNotificationCountSec(this.totalCount);
        }

        // this.popupData = jsonData.response.data.publishedProfileData;
        // this.popupDataStatus = jsonData.response.data.publishableStatus;


        // if (fullDashData.userData.Published == false) {
        //   this.showPublishProfilePopup = true;
        //   if (this.localStore.getPublishProfile() != false) {
        //     this.showPublishProfilePopupOneTime = true;
        //     // this.clearConstant();
        //   }
        // }

        // if (fullDashData.userData.Published == false) {
        //   this.showPublishMsg = true;
        // }

        if (this.genericFunc.isEmpty(fullDashData.userData.ProfileImageURL) == true) {
          let currentProfile = JSON.parse(this.localStore.getProfile());
          currentProfile.ProfileImageURL = fullDashData.userData.ProfileImageURL
          this.localStore.setProfile(JSON.stringify(currentProfile));
        }


        if (fullDashData.featuredUsers.length > 0) {
          this.featuredCoach = fullDashData.featuredUsers;
          this.showCurrentCoach(this.featuredCoach[0]);
          this.setSlider('slider1');
        }

        if (fullDashData.lastSearchUsers.length > 0 && this.isLoggedIn) {
          this.lastSearchCoachs = fullDashData.lastSearchUsers;
          this.showCurrentCoach(this.lastSearchCoachs[0], 'lastSearchSec');
          this.setSlider('slider2');
        }

        if (fullDashData.sponsoredUsers.length > 0) {
          this.sponsoredCoachs = fullDashData.sponsoredUsers;
          this.showCurrentCoach(this.sponsoredCoachs[0], 'sponsoredCoachsSec');
          this.setSlider('slider0');
        }

        if (fullDashData.allSkills.length > 0) {
          this.allSkills = jsonData.response.data.allSkills;
          this.allSkills.forEach((singleSkillData: any) => {
            this.showCurrentCoach(singleSkillData.skillData[0], 'skillSec');
          });
          this.setSlider('currentSkill0');
        }

        setTimeout(() => {
          this.ref.tick();
        }, 50);

        console.log(jsonData.response.data.unreadMessagesCount.unreadMessages)

        // if (jsonData.response.data.unreadMessagesCount.unreadMessages > 0) {
          this.unreadMessagesCount = jsonData.response.data.unreadMessagesCount.unreadMessages;
         //this.headerSec.checkMessageBadgeSec(this.unreadMessagesCount);
          // // this.unreadMessageCounter.next(unreadMessagesCount);
          this.genericFunc.setUnreadMessagesCountSec(this.unreadMessagesCount);
        // }
      } else {
        this.toast.error(jsonData.response.err, Constants.errorTitle);
      }
    }

  }

  loginPageLink() {
    this._router.navigateByUrl(Constants.loginPath);
  }

  SignupPageLink() {
    this._router.navigateByUrl(Constants.signUpPath);
  }

  setSlider(sliderVal: any) {
    // if (this.selectredRow == '') {
    //   this.selectredRow = sliderVal;
    // }
  }

  closePublishPopup() {
    // this.localStore.setPublishProfile(false);
    // this.showPublishProfilePopup = false;
    // this.clearConstant();
  }

  clearConstant() {
    Constants.currentPage = "";
  }

  showCurrentCoach(coachData: any, currentRow: any = '') {
    if (this.currentCoachAdded == false) {
      this.currentCoachData = coachData;
      this.currentCoachDataLoaded = true;
      this.currentCoachAdded = true;
      this.currentSliderRow = currentRow;
      this.activeRow = currentRow;
    }
  }

  setSkillsWithoutLogin(skills: any) {
    if (skills) {
      let dummyObject: Object;
      let array: any = [];

      for (let i = 0; i < skills.length; i++) {
        dummyObject = {
          'id': i,
          'itemName': skills[i]['SkillTitle']
        }
        array.push(dummyObject);
      }
      this.localStore.setSkills(array);
    }
    // if (!this.genericFunc.isUserLoggedIn()) {
    //   this.apiManager.getMethod(Constants.getAllSkills, true).subscribe((jsonData) => {
    //     if (jsonData.response) {
    //       if (jsonData.status == 1) {
    //         if (jsonData.response.data) {


    //         }
    //       } else {
    //         // this.toast.error(jsonData.response.err, Constants.errorTitle);
    //       }
    //     }
    //   }, error => {
    //   })
    // }
  }
  onNotifyClicked(val: any) {
    if (val == 'Hire Request Send') {
      window.location.reload();
    }
  }

  getStyleData() {
    this.loading = true;
    this.setStyle = true
    let domainName = this.genericFunc.getSubDomain()

    this.HomeService.getStyleData(domainName[0]).subscribe((jsonData) => {
      this.setStyle = false
      this.loading = false;
      let stylesString = jsonData.response.data.CssStyle
      this.localStore.setStyle(JSON.stringify(stylesString));
      this.homeHtml = jsonData.response.data.HomepageContent;


      // Remove curly braces and split the string by commas
      var stylesArray = stylesString.slice(1, -1).split(',');

      // Convert each pair into an object
      stylesArray.map(function(item:any) {
        // Split each pair into key and value
        var keyValue = item.trim().split(':');
        
        // Create and return an object with key and value
        document.documentElement.style.setProperty(`${keyValue[0].trim()}`, keyValue[1].trim());
        // return {
        //   name: keyValue[0].trim(), value: keyValue[1].trim()
        // };
      });

      let backendUrl = jsonData.response.data.LogoURL
      this.localStore.setLogo(JSON.stringify(backendUrl));
      // let backendUrl = 'https://png.pngtree.com/element_pic/00/16/07/06577d261edb9ec.jpg'
      Constants.logoImg = backendUrl !== null  ? backendUrl : Constants.logoImg

      // console.log(stylesObjects)
    })
  }

  ngOnDestroy() {
    this.loading = false;
    // if (this.response) {
    //   this.response.unsubscribe();
    // }

    if (this.getHomeDataWithLogin) {
      this.getHomeDataWithLogin.unsubscribe();
    }

    if (this.getHomeDataWithOutLogin) {
      this.getHomeDataWithOutLogin.unsubscribe();
    }
  }
}
